export const localesForTranslations = {
    fr: 'fr_FR',
    en: 'en_GB',
    es: 'es_ES',
    it: 'it_IT',
    de: 'de_DE'
}

export const localesForApis = {
    fr: 'fr-FR',
    en: 'en-GB',
    es: 'es-ES',
    it: 'it-IT',
    de: 'de-DE'
}
