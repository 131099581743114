<template>
    <div class="AlertModal">
        <modal
            v-model="showModal"
            :title="title"
        >
            <div class="text-center">
                <v-icon
                    v-if="type === 'error'"
                    color="error"
                    size="x-large"
                    class="AlertModal-icon text-center"
                    icon="mdi mdi-close-circle-outline"
                />
                <v-icon
                    v-else-if="type === 'success'"
                    color="success"
                    size="x-large"
                    icon="mdi mdi-check-circle-outline"
                />
            </div>
            <v-alert
                v-if="messageHTML !== '' || message !== ''"
                class="AlertModal-additionalInfo my-4"
                color="error"
                density="compact"
                prominent
                variant="tonal"
            >
                <p
                    v-if="messageHTML !== ''"
                    class="ma-0"
                    v-html="$sanitize(messageHTML)"
                />
                <p
                    v-else
                    class="ma-0"
                >
                    {{ message }}
                </p>
            </v-alert>
            <v-alert
                v-if="externalMessage !== ''"
                class="AlertModal-additionalInfo mb-6"
                color="info"
                density="compact"
                prominent
                variant="tonal"
            >
                <div class="AlertModal-additionalInfoTitle">
                    {{ $t('error:additional_information') }}
                </div>
                <div
                    class="AlertModal-additionalInfoMessage"
                    v-html="$sanitize(externalMessage)"
                />
            </v-alert>
            <v-btn
                v-for="(action, index) in actions"
                :key="'alert-action-' + index"
                class="mb-4"
                color="primary"
                elevation="0"
                size="large"
                type="submit"
                @click.prevent="action.onClick"
            >
                {{ action.label }}
            </v-btn>
            <div v-if="showDebugInformations">
                <v-divider class="mb-4" />
                <p class="mb-4">
                    <strong>
                        {{ t('debug:infos_debug_only') }}
                    </strong>
                </p>
                <pre
                    v-if="debugObject !== undefined"
                    class="AlertModal-debugObject"
                ><!--
                    -->{{ JSON.stringify(debugObject, null, 4) }}
                </pre>
            </div>
            <template #actions>
                <v-btn
                    class="bg-primary ml-2"
                    @click="closeModal"
                >
                    {{ $t(closeButtonText) }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'

const store = useStore()
const { t } = useI18n()

const actions = ref([])
const debugObject = ref(undefined)
const externalMessage = ref('')
const message = ref(t('error:default:message'))
const messageHTML = ref('')
const showModal = ref(false)
const title = ref(t('error:default:title'))
const type = ref('error')
const withCrossIcon = ref(true)

const closeButtonText = ref('button:close')
let closeButtonCallback = () => {}

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const showDebugInformations = computed(() => debugInformationsDisplay.value && debugObject.value !== undefined)

const onShowAlertModal = (params) => {
    if (params) {
        if (!isEmpty(params.actions)) {
            actions.value = params.actions
        } else if (
            typeof params.action !== 'undefined' &&
            params.action.label &&
            typeof params.action?.onClick === 'function'
        ) {
            actions.value = [params.action]
        }

        if (typeof params.closeAction !== 'undefined') {
            closeButtonText.value = params.closeAction.label ?? t('button:close')
            closeButtonCallback = params.closeAction.onClick ?? (() => {})
        }

        debugObject.value = typeof params.debugObject !== 'undefined' ? params.debugObject : undefined
        externalMessage.value = params.externalMessage ? params.externalMessage.replace(/\n/g, '<br>') : ''
        message.value = params.message ? params.message.replace(/\\n/g, '<br>') : ''
        messageHTML.value = params.messageHTML ? params.messageHTML.replace(/\\n/g, '<br>') : ''
        title.value = params.title ?? ''
        type.value = params.type ?? 'error'
        withCrossIcon.value = !isEmpty(params.crossIcon) ? params.crossIcon : true
    }

    openModal()
}

const closeModal = async () => {
    await closeButtonCallback()

    showModal.value = false
}

const openModal = () => {
    showModal.value = true
}

onMounted(() => {
    emitter.on('showAlertModal', onShowAlertModal)
    emitter.on('hideAlertModal', closeModal)
})

onUnmounted(() => {
    emitter.off('showAlertModal', onShowAlertModal)
    emitter.off('hideAlertModal', closeModal)
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AlertModal {
    &-container {
        &.Modal-mask {
            z-index: 999;
        }
    }

    &-content {
        font-size: 15px !important;
        color: black !important;
    }

    &-icon {
        font-size: 70px !important;
    }

    &-additionalInfo {
        margin-bottom: 30px;
    }

    &-additionalInfoTitle {
        font-weight: bold;
    }

    &-debugObject {
        overflow-x: auto;
        width: 100%;
        padding: 10px;
        background: #f0f0f0;
        font-size: 12px;
    }

    :deep(.Modal-contentDesktop) {
        min-height: 0;
    }
}
</style>
