import store from '@/StoreWeb/store/store'
import { isEmpty } from '@/StoreWeb/utils/global-utils'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import { localesForTranslations } from '@/StoreWeb/js/locales-conversion-table'
import config from 'config'
import cachios from 'cachios'
import router from '@/router/router'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as loginMutationTypes from 'user/Login/store/mutation-types'
import * as loginActionTypes from 'user/Login/store/action-types'
import LoginManager from 'user/Login/managers/LoginManager'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'

export async function loadSession (route) {
    const action = route.params.action

    const session = await store.dispatch(applicationSessionActionTypes.READ_APPLICATION_SESSION, {
        id: route.query.sessionId,
        action
    })

    await applySessionUserToken(session)

    return session
}

export async function applySessionUserToken (session) {
    if (
        !isEmpty(session.data) &&
        !isEmpty(session.data.userToken) &&
        (!store.state.debuggingModule.debugMode || !localStorage.getItem('usingFakeUserToken'))
    ) {
        let userToken

        try {
            userToken = JSON.parse(session.data.userToken)
        } catch (e) { // If the token is using the old structure instead of a stringified object
            userToken = {
                accessToken: session.data.userToken,
                expirationDate: parseInt(session.data.expirationDate),
                refreshToken: session.data.refreshToken
            }
        }

        const isTokenValid = await checkTokenValidity(userToken)

        if (!isTokenValid) {
            delete cachios.axiosInstance.defaults.headers.common.Authorization

            const currentRoute = store.state?.currentRoute

            if (currentRoute?.name === 'internalLinks' && currentRoute?.params?.action) {
                const baseUrl = `${window.location.origin}/internalLinks/${currentRoute?.params?.action}?sessionId=${session.id}&initialRequest=${externalActionTypes.LOG_IN}`

                session.sessionContext = {
                    currentStep: baseUrl,
                    resumeStepSuccess: `${baseUrl}&status=success`,
                    resumeStepError: `${baseUrl}&status=error`
                }
            }

            delete session?.data.userToken
            delete session?.data.refreshToken
            delete session?.data.expirationDate

            await store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, session)

            await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)

            const externalLink = router.resolve({ name: 'externalLinks', params: { action: externalActionTypes.LOG_IN }, query: { sessionId: session.id } })

            if (!isEmpty(externalLink)) {
                window.location.href = window.location.origin + externalLink.href
            }
        }
    }
}

async function checkTokenValidity (userToken) {
    const response = await store.dispatch(`login/${loginActionTypes.CHECK_USER_TOKEN_VALIDITY}`, {
        accessToken: userToken.accessToken
    })

    if (response.isUserTokenValid === true) {
        LoginManager.adaptTokenRefreshSafetyDelayIfNeeded(userToken)
        LoginManager.setUserTokenCookie(userToken)
        LoginManager.applyUserTokenToAxios()
        store.commit(`login/${loginMutationTypes.SET_USER_TOKEN}`, LoginManager.userToken)

        if (response.userInfos.attributes) {
            if (LoginManager.isAdditionalInfoRequired) {
                LoginManager.setUserInfosCookie(response.userInfos)
                store.commit(`login/${loginMutationTypes.SET_USER_INFOS}`, LoginManager.userInfos)
                store.commit(`login/${loginMutationTypes.SET_RAW_USER_INFOS}`, LoginManager.rawUserInfos)

                switch (config.userweb.kas_user_infos_enrichment_method) {
                    case 'sytral':
                        await store.dispatch(userActionTypes.GET_PERSONAL_INFO)
                        break
                }
            }
        }

        await store.commit(`login/${loginMutationTypes.SET_AUTHENTICATED}`, true)
    } else if (response.isUserTokenValid === false) {
        delete cachios.axiosInstance.defaults.headers.common.Authorization
    }

    return response.isUserTokenValid
}

export function getSessionLocale (session) {
    let language = session?.data?.language

    if (language) {
        let foundLocale

        if (language.length > 2) {
            language = language.substring(0, 2)
        }

        if (!isEmpty(localesForTranslations[language])) {
            foundLocale = language
        }

        if (typeof config.i18n.supported_locales[foundLocale] !== 'undefined') {
            return foundLocale
        }
    }

    return undefined
}
