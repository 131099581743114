<template>
    <div
        ref="accountLayoutReference"
        class="AccountLayout"
    >
        <global-loader
            :showGlobalLoader="showGlobalLoader.value"
            :subtitle="showGlobalLoader.subtitle"
            :title="showGlobalLoader.title"
            class="AccountLayout-globalLoader"
        />
        <header-bar-sytral
            v-if="!config.web_harmony.header && !isMobileApp"
            :style="{ zIndex : headerBarZIndex }"
            class="AccountLayout-header"
        />
        <header-bar
            v-else-if="config.web_harmony.header && !isMobileApp"
            class="AccountLayout-header"
        />
        <the-sidebar
            v-if="config.web_harmony.sidebar"
            :configuration="configuration"
            :use-router-link="true"
        />
        <div
            :class="{
                'AccountLayout-body--sytral': !config.web_harmony.header,
                'AccountLayout-body--mobileApp': isMobileApp,
            }"
            class="AccountLayout-body"
        >
            <v-container
                v-show="!isWalletLoading"
                class="AccountLayout-container"
            >
                <h1
                    v-if="pageConfig.title !== ''"
                    class="AccountLayout-title h1"
                >
                    {{ pageConfig.title }}
                </h1>
                <v-container class="pa-0">
                    <v-row class="AccountLayout-content gap-6">
                        <v-col
                            v-if="!isMobileApp && $vuetify.display.mdAndUp"
                            cols="3"
                            class="AccountLayout-sidebar"
                        >
                            <account-nav
                                :accountNav="navigationMixins.getAccountNav.value"
                                :inNavBar="false"
                                class="AccountLayout-sidebarNav"
                            />
                        </v-col>
                        <v-col
                            class="AccountLayout-main"
                            :cols="$vuetify.display.mdAndUp ? '8' : '12'"
                        >
                            <user-with-avatar />
                            <router-view />
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </div>
        <footer-bar-sytral
            v-if="!config.web_harmony.footer && !isMobileApp"
        />
        <footer-bar v-else-if="!isMobileApp" />
        <main-nav
            v-if="!isMobileApp"
            v-click-outside="clickOutside"
        />
        <alert-modal />
        <!--        TODO : move later -->
        <account-fare-media-add-modal />
        <v-snackbar
            v-model="successToastVisibility"
            class="AccountLayout-alert"
            color="success"
            location="top"
            :timeout="getToastTimeoutConfig"
        >
            <div v-html="$sanitize($t(feedbackSuccess))" />
            <template #actions>
                <v-btn
                    elevation="0"
                    icon="mdi-close"
                    size="small"
                    aria-hidden="true"
                    :aria-label="$t('button:close')"
                    @click="successToastVisibility = false"
                />
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="errorToastVisibility"
            class="AccountLayout-alert"
            color="error"
            location="top"
            :timeout="getToastTimeoutConfig"
        >
            <div v-html="$sanitize($t(feedbackError))" />
            <template #actions>
                <v-btn
                    elevation="0"
                    icon="mdi-close"
                    size="small"
                    aria-hidden="true"
                    :aria-label="$sanitize($t('button:close'))"
                    @click="errorToastVisibility = false"
                />
            </template>
        </v-snackbar>
        <debug-modal v-if="debugMode" />
        <debug-config-modal />
        <button
            v-if="config?.debug_mode"
            class="AccountLayout-debugButton"
            @click="showDebugConfigModal"
        >
            DEBUG
        </button>
    </div>
</template>

<script setup>
import { ref, computed, onUnmounted, onBeforeMount, onMounted } from 'vue'
import { useStore } from 'vuex'
import FooterBar from '@/StoreWeb/components/footer/FooterBar'
import GlobalLoader from '@/StoreWeb/components/common/GlobalLoader'
import HeaderBar from '@/StoreWeb/components/header/HeaderBar'
import AlertModal from '@/StoreWeb/components/common/AlertModal'
import MainNav from '@/StoreWeb/components/common/nav/MainNav'
import AccountNav from '@/StoreWeb/components/account/AccountNav'
import GlobalManager from '@/StoreWeb/managers/GlobalManagerSingleton'
import DebugModal from '@/StoreWeb/components/common/debug/DebugModal.vue'
import DebugConfigModal from '@/StoreWeb/components/common/debug/DebugConfigModal.vue'
import AccountFareMediaAddModal from '@/StoreWeb/components/account/association/AccountFareMediaAddModal'
import HeaderBarSytral from '@/StoreWeb/components/header/HeaderBarSytral.vue'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import FooterBarSytral from '@/StoreWeb/components/footer/FooterBarSytral.vue'
import UserWithAvatar from '@/StoreWeb/components/account/UserWithAvatar'
import { useNavigationMixins } from '@/StoreWeb/js/navigation-mixins'
import config from 'config'
import { useI18n } from 'vue-i18n'
import { showDebugConfigModal } from '@/StoreWeb/js/composables/debug-utils'
import configuration from '../../../config/wh-config.json'
import { TheSidebar } from '@instant-system/web-harmony-widget'
import { getIsAuthenticated } from '@/StoreWeb/js/composables/login-utils'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { autoSetSelectedFareMedia } from '@/StoreWeb/js/mixins/wallet-utils'

const store = useStore()
const navigationMixins = useNavigationMixins()
const { t } = useI18n()

const accountLayoutReference = ref(null)

const globalManager = GlobalManager.getInstance()

const debugMode = computed(() => store.state.debuggingModule.debugMode)
const headerBarZIndex = computed(() => store.state.headerBarZIndex)
const isMobileApp = computed(() => store.state.isMobileApp)
const pageConfig = computed(() => store.state.pageConfig)
const showGlobalLoader = computed(() => store.state.showGlobalLoader)
const feedbackSuccess = computed(() => store.state.feedbackSuccess)
const feedbackError = computed(() => store.state.feedbackError)
const showSuccessToast = computed(() => store.state.showSuccessToast)
const showErrorToast = computed(() => store.state.showErrorToast)
const getToastTimeoutConfig = computed(() => config.toast_timeout)
const isWalletLoading = computed(() => store.state.userModule.userPendingRequests.getWallet)

const successToastVisibility = computed({
    get: () => showSuccessToast.value,
    set: (newValue) => {
        store.commit(mainMutationTypes.SET_SHOW_SUCCESS_TOAST, newValue)
    }
})

const errorToastVisibility = computed({
    get: () => showErrorToast.value,
    set: (newValue) => {
        store.commit(mainMutationTypes.SET_SHOW_ERROR_TOAST, newValue)
    }
})

function clickOutside (event) {
    globalManager.onMainNavClickOutside(event, accountLayoutReference.value)
}

async function getWallet () {
    if (!getIsAuthenticated()) {
        return
    }
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, {
        keepShow: true,
        title: t('default_loading:title'),
        subtitle: t('default_loading:subtitle'),
        value: true
    })
    const wallet = await store.dispatch(userActionTypes.GET_WALLET, {
        wsOptions: {
            cacheAsync: false,
            cacheValidity: 0
        }
    }).finally(() => {
        store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: false, resetTexts: true, value: false })
    })
    if (config.features.restore_last_fare_media && wallet) {
        autoSetSelectedFareMedia()
    }
}

onMounted(async () => {
    if (store.getters.getWallet?.providers?.length === 0) {
        await getWallet()
    }
})

onBeforeMount(async () => {
    await getWallet()
})

onUnmounted(() => {
    document.body.classList.remove('body--layoutAccount')
    navigationMixins.desactiveEmitter()
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.AccountLayout {
    @extend %baseLayout;

    &-body {
        @extend %baseLayoutBody;

        padding-top: 65px;

        // TODO manage side bar + footer into WH
        padding-bottom: 102px;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            padding-bottom: 52px;
            padding-left: 120px;
        }

        &--sytral {
            padding-top: 68px;
            padding-left: 0;

            @media (min-width: $desktop-breakpoint) {
                padding-top: 86px;
            }
        }

        &--mobileApp {
            padding-top: 0;
        }
    }

    &-container {
        width: 100%;
        margin: 0 auto;
        padding: 25px 25px 80px;

        @media (min-width: $desktop-breakpoint) {
            width: 975px;
            padding-right: 0;
            padding-left: 0;
        }

        @media (min-width: $desktopMedium-breakpoint) {
            width: 1100px;
            padding-right: 0;
            padding-left: 0;
        }
    }

    &-content {
        @media (min-width: $tablet-breakpoint) {
            display: flex;
            align-items: flex-start;
        }
    }

    &-sidebar {
        width: 325px;
        padding: 10px 25px;
        border-radius: $border-radius-small;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, .09);
        background: $sidebar-backgroundColor;
    }

    &-main {
        @media (min-width: $tablet-breakpoint) {
            flex-grow: 1;
        }
    }

    &-title {
        margin-bottom: 30px;
    }

    &-alert {
        position: fixed;
        z-index: 1000;
        top: 10px;
        left: 50%;
        width: calc(100% - #{$padding-medium});
        font-family: $fontLato;
        transform: translateX(-50%);

        @media screen and (min-width: $tablet-breakpoint) {
            width: auto;
        }
    }

    &-debugButton {
        position: fixed;
        z-index: 10002;
        right: 0;
        bottom: 0;
        padding: 10px;
        border: none;
        border-radius: $border-radius_medium 0 0 0;
        background: $color-brandPrimary;
        cursor: pointer;
        font-weight: bold;
        color: $color-lightest;
        transition: background-color .3s;

        &:hover,
        &:active,
        &:focus {
            background: $color-brandPrimaryDark;
        }
    }
}
</style>
