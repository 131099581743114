<template>
    <main class="Cart">
        <div class="Container Cart-container">
            <router-link :to="{ name: 'catalog' }">
                {{ $t('cart:continue_shopping') }}
            </router-link>
            <h1 class="Cart-title">
                {{ $t('cart:title') }}
            </h1>
            <block-loader v-if="showCartLoader" />
            <div v-else-if="!isEmptyCart">
                <v-row>
                    <v-col
                        class="Cart-container"
                        cols="12"
                        md="7"
                    >
                        <cart-content
                            class="Cart-content"
                            :isEditable="true"
                        />
                        <span
                            v-if="basketHasCardReaderMandatory"
                            class="Cart-cardReaderMandatory"
                        >
                            <icomoon-icon
                                :layersCount="3"
                                name="CardReader"
                                class="Cart-cardReaderMandatoryIcon"
                            />
                            <span
                                v-html="$sanitize($t('cart:card_reader_mandatory'))"
                            />
                        </span>
                        <address-selector
                            v-if="isAuthenticated"
                            :isCartConfirmed="isCartConfirmed"
                            class="Cart-addressSelector"
                            @address-forms-error="updateAddressFormsError"
                            @address-changed="cartConfirmation"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        md="5"
                        class="pl-md-15"
                    >
                        <v-card
                            elevation="5"
                        >
                            <v-card-title>
                                <div class="Cart-immediateTotal">
                                    <span class="Cart-immediateTotalLabel">{{ $t('cart:total_to_settle_immediately') }}</span>
                                    <formatted-fare
                                        :currency="'EUR'"
                                        :fare="cartImmediateAmount"
                                        size="Small"
                                        tag="span"
                                        class="Cart-immediateTotalFare"
                                    />
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <sales-terms
                                            :isChecked="false"
                                            :labelFor="'js-acceptSalesTerms'"
                                            :isRequired="true"
                                            class="Cart-salesTerms"
                                            name="cgv"
                                            @sales-terms-approval-changed="updateSalesTermsAccepted"
                                        />
                                        <alert-message
                                            v-if="getShowAcceptSalesTermsAlert"
                                            :message="$t('sales_terms:error:not_approved')"
                                            type="error"
                                            class="Cart-uncheckedSalesTermsError"
                                        />

                                        <v-spacer />
                                        <v-btn
                                            class="mb-4"
                                            color="primary"
                                            width="100%"
                                            :disabled="isConfirmButtonDisabled"
                                            @click="cartConfirmationButtonClicked"
                                        >
                                            {{ $t('button:order') }}
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn
                                            variant="text"
                                            color="primary"
                                            width="100%"
                                            @click="clearCart"
                                        >
                                            {{ $t('button:cancel:order') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div
                v-else
                class="Cart-emptyCart"
            >
                <p class="Cart-emptyCartMessage">
                    {{ $t('cart:empty') }}
                </p>
                <v-btn
                    :to="{ name: 'catalog' }"
                    color="primary"
                    variant="flat"
                >
                    {{ $t('button:back_to_catalog') }}
                </v-btn>
            </div>
        </div>
    </main>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { isEmpty } from 'global-utils'
import { cartDeletedHandler, storeConfirmedCartInfos } from '@/StoreWeb/js/composables/cart-utils'
import AddressSelector from '@/StoreWeb/components/address/AddressSelector'
import CartContent from '@/StoreWeb/components/cart/CartContent'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import SalesTerms from '@/StoreWeb/components/checkout/SalesTerms'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'

const store = useStore()
const router = useRouter()
const { isAuthenticated } = useLogin()

const billingAddress = ref(null)
const deliveryAddress = ref(null)
const addressFormsError = ref(null)
const salesTermsAccepted = ref(false)
const showAcceptSalesTermsAlert = ref(false)
const isCartConfirmed = ref(false)

const cartPendingRequests = computed(() => store.state.cartModule.cartPendingRequests)
const getCart = computed(() => store.getters.getCart)
const getAddressTemplates = computed(() => store.getters.getAddressTemplates)
const getShowAcceptSalesTermsAlert = computed(() => showAcceptSalesTermsAlert.value)
const isConfirmButtonDisabled = computed(() => !salesTermsAccepted.value || addressFormsError.value)
const isEmptyCart = computed(() => getCart.value.baskets.length === 0 && getCart.value.packages.length === 0 && getCart.value.plans.length === 0)
const showCartLoader = computed(() => cartPendingRequests.value.getCart)

const cartImmediateAmount = computed(() => {
    if (isEmpty(getCart.value)) {
        return 0
    }

    return getCart.value.immediateAmountIncludingTaxes || 0
})
const basketHasCardReaderMandatory = computed(() => {
    return getCart.value?.baskets.some(basket => basket?.basket?.products.some(product => product?.item?.deliveryMode === 'CARDREADER_MANDATORY'))
})

onMounted(() => {
    if (isAuthenticated.value && isEmpty(getAddressTemplates)) {
        store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    }
})

async function clearCart () {
    if (!isEmpty(getCart) && !isEmpty(getCart.value.id)) {
        const isCartDeleted = await store.dispatch(cartActionTypes.DELETE_CART, {
            id: getCart.value.id
        })

        if (isCartDeleted) {
            await cartDeletedHandler()
        }
    }
}

function cartConfirmationButtonClicked () {
    if (salesTermsAccepted.value) {
        isCartConfirmed.value = true
    } else {
        showAcceptSalesTermsAlert.value = true
        isCartConfirmed.value = false
    }
    if (!isAuthenticated.value && isCartConfirmed.value) {
        cartConfirmation({
            billingAddress: billingAddress.value,
            deliveryAddress: store.getters.getCart.baskets.find((value) => value.basket.deliveryMode === 'POSTAL') ? deliveryAddress.value : null
        })
    }
}

async function cartConfirmation (data) {
    isCartConfirmed.value = false
    storeConfirmedCartInfos(data)
    await router.push({ name: 'cartConfirmation' })
}

function updateSalesTermsAccepted (isChecked) {
    salesTermsAccepted.value = isChecked
    if (isChecked) {
        showAcceptSalesTermsAlert.value = false
    }
}

function updateAddressFormsError (error) {
    addressFormsError.value = error
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.Cart {
    background: $color-lightest;

    &-title {
        @extend %h1;

        margin-bottom: 30px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: $s6;
    }

    &-immediateTotal {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: $s3;
        margin-bottom: $s5;
    }

    &-immediateTotalLabel {
        flex: 1;
        font-size: $font-small;
        white-space: break-spaces;
    }

    &-salesTerms {
        margin-bottom: 25px;
    }

    &-uncheckedSalesTermsError {
        margin-bottom: 30px;
    }

    &-emptyCart {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $s10;
        padding-top: $s3;
    }

    &-emptyCartMessage {
        font-size: $font-xsmall;
    }

    &-cardReaderMandatory {
        display: flex;
        gap: $s2;
        padding: $s4;
        border-radius: $border-radius-medium;
        background: $color-brandSecondaryLightBackground;
        font-weight: bold;
        color: $color-brandSecondary;
    }

    &-cardReaderMandatoryIcon {
        margin-top: 2px;
        font-size: $font-medium;
    }
}
</style>

<style lang='scss'>
/* stylelint-disable */
// Case really special where we need to bypass the a css style

@import 'globalScss';

.Cart-link {
    color: $color-brandPrimary;
    transition: color .3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        color: $color-brandPrimaryDark;
    }
}
/* stylelint-enable */
</style>
