<template>
    <v-card class="PaymentRegularCard">
        <img
            class="PaymentRegularCard-image"
            :src="$versionedUrl(require(`@/StoreWeb/assets/img/${env}/${icon}.svg`))"
            :alt="icon"
        >
        <div class="PaymentRegularCard-block">
            <span class="PaymentRegularCard-title h1">{{ $t(title) }}</span>
            <span
                class="PaymentRegularCard-description"
                v-html="$sanitize($t(description))"
            />
            <v-btn
                color="primary"
                size="large"
                class="PaymentRegularCard-button"
                @click="mainAction()"
            >
                {{ getButtonLabel }}
            </v-btn>
        </div>
    </v-card>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['openSupportReaderModal'])

const props = defineProps({
    buttonLabel: {
        type: String,
        default: ''
    },
    deliveryMode: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    id: {
        type: Number,
        required: true
    },
    params: {
        type: Object,
        default: null
    },
    title: {
        type: String,
        required: true
    }
})

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const env = ref(process.env.VUE_APP_NETWORK_ID)

const authenticated = computed(() => store.state.login.authenticated)
const getButtonLabel = computed(() => {
    return props.buttonLabel ? t(props.buttonLabel) : authenticated.value ? t('payment:success:order_link') : props.params ? t('payment:success:wallet_light') : t('button:back_to_home')
})

function mainAction () {
    if (['DISTRIBUTION_CARDREADER', 'REGULARIZATION'].includes(props.deliveryMode)) {
        emit('openSupportReaderModal')
    } else if (authenticated.value) {
        router.push({ name: 'orderDetails', params: { id: props.id } })
    } else if (props.params) {
        router.push({ name: 'walletLight', params: props.params })
    } else {
        router.push({ name: 'home' })
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentRegularCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s6;
    padding: $s8 $s4;
    box-shadow: none;
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
        flex-direction: row;
        padding: $s8;
        text-align: left;
    }

    &-image {
        width: 250px;
        max-height: 100px;

        @media (min-width: $desktop-breakpoint) {
            max-height: 180px;
        }
    }

    &-block {
        display: flex;
        flex-direction: column;
        gap: $s6;
    }

    &-title {
        font-size: $font-large;
        font-weight: 600;
        line-height: $font-xlarge;
    }

    &-description {
        font-size: $font-small;
        line-height: $font-medium;
    }

    &-details {
        width: 100%;
        padding: $s4;
        border-radius: $borderRadius-xlarge;
        background-color: $color-white;
    }

    &-button {
        @media (min-width: $desktop-breakpoint) {
            max-width: max-content;
        }
    }
}
</style>
