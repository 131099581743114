import { useStorageManager } from '@/StoreWeb/js/composables/storage-utils'

const storage = useStorageManager()

export default {
    install (app) {
        app.config.globalProperties.$versionedUrl = (url) => {
            return storage.getVersionedUrl(url)
        }
    }
}
