<template>
    <div class="WalletLight">
        <div class="Container">
            <h1 class="h1">
                {{ $t('wallet-light:title') }}
            </h1>
            <div class="WalletLight-result">
                <a
                    class="WalletLight-backButton"
                    @click="$router.go(-1)"
                >
                    {{ $t('button:back') }}
                </a>
                <template v-if="getShowSupportContent">
                    <div class="WalletLight-subtitle mb-4">
                        <v-icon class="mdi mdi-card-bulleted mr-2 WalletLight-tabIcon" />
                        <h2 class="h3 ma-0">
                            {{ $t('wallet-light:card') }}
                        </h2>
                    </div>
                    <fare-media-item
                        :fareMedia="supportContent"
                        :showActions="false"
                        tag="div"
                        class="WalletLight-fareMedia"
                        @update-fare-media="openSupportReaderModal"
                    />
                    <support-reader-modal
                        v-model="showSupportReaderModal"
                        :headerTitle="$t('card_reader:scan_modal:title')"
                        :operationType="'WRITE'"
                        :supportId="supportContent.id"
                        @operation-finished="onSupportReaderOperationFinished"
                    />
                </template>
                <block-loader v-else />
            </div>
        </div>
    </div>
</template>

<script setup>
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import { isEmpty } from 'global-utils'
import { computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import FareMediaItem from '@/StoreWeb/components/fare-media/FareMediaItem'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import { useCardReader } from '@/StoreWeb/js/composables/card-reader-utils'

const store = useStore()
const route = useRoute()
const {
    showSupportReaderModal,
    openSupportReaderModal,
    onSupportReaderOperationFinished
} = useCardReader()

const supportContent = computed(() => store.state.anonymousModule.supportContent)
const getShowSupportContent = computed(() => !isEmpty(supportContent.value))

onMounted(() => {
    loadSupportContent()
})

onUnmounted(() => {
    store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, undefined)
})

async function loadSupportContent () {
    if (isEmpty(supportContent.value)) {
        const params = {
            providerId: route.params.providerId,
            fareMediaId: route.params.fareMediaId
        }
        const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)
        if (content?.id) {
            store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.WalletLight {
    &-result {
        margin-top: $s8;
    }

    &-subtitle {
        @include verticalCenter;
    }

    &-backButton {
        display: block;
        margin-bottom: $s5;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        font-family: $fontFamily-default;
        font-size: 16px;
        font-weight: $fontWeight-defaultBold;
        color: $color-brandPrimary;
        transition: color .3s;

        &:hover,
        &:active,
        &:focus {
            color: $color-brandPrimaryDark;
        }
    }

    &-tabIcon {
        font-size: $font-large;
        color: $primary_color !important;
    }

    &-fareMedia {
        background: $color-white;
    }
}
</style>
