<template>
    <div class="SaleOptionEmailChoice">
        <div
            v-if="getIsEditing"
            class="SaleOptionEmailChoice-editForm"
        >
            <labelled-input
                :defaultValue="defaultEmail"
                :inputType="'text'"
                :label="$t('form:user_infos:email:label')"
                class="SaleOptionEmailChoice-field"
                @field-value-changed="emailUpdated"
            />
            <button
                :title="$t('button:validate')"
                class="SaleOptionEmailChoice-button"
                @click="updateEmail"
            >
                <icomoon-icon
                    name="Check"
                    class="SaleOptionEmailChoice-buttonIcon"
                />
            </button>
        </div>
        <div v-else-if="selectedEmail !== ''">
            <p>{{ $t('sale_option:basket_info:email:explanations') }}</p>
            <button
                class="SaleOptionEmailChoice-editEmailButton"
                @click="enableEditing"
            >
                {{ getSelectedEmail }}
                <icomoon-icon
                    name="Edit"
                    class="SaleOptionEmailChoice-editEmailButtonIcon"
                />
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeMount } from 'vue'
import { isEmpty } from 'global-utils'
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { useStore } from 'vuex'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import Cookies from 'js-cookie'

const store = useStore()
const { userInfos } = useLogin()

const emit = defineEmits(['updateEmail'])

const defaultEmail = ref('')
const isEditing = ref(false)
const selectedEmail = ref(null)

const addToCartEmail = computed(() => store.state.cartModule.addToCartEmail)
const getIsEditing = computed(() => {
    return isEditing.value
})
const getSelectedEmail = computed(() => {
    return selectedEmail.value ?? ''
})

function emailUpdated (email) {
    selectedEmail.value = email
}

function setDefaultEmail () {
    if (selectedEmail.value === null && (!isEmpty(userInfos.value.email) || !isEmpty(addToCartEmail.value))) {
        selectedEmail.value = (!isEmpty(addToCartEmail.value) ? addToCartEmail.value : userInfos.value.email)
        updateEmail()
    }
}

function updateEmail () {
    isEditing.value = false
    emit('updateEmail', {
        basketInfo: 'EMAIL',
        value: {
            email: selectedEmail.value
        }
    })
}

function enableEditing () {
    isEditing.value = true
}

onBeforeMount(() => {
    setDefaultEmail()
})

onMounted(() => {
    if (!isEmpty(userInfos.value.email)) {
        defaultEmail.value = userInfos.value.email
    } else if (addToCartEmail.value) {
        defaultEmail.value = addToCartEmail.value
    } else {
        const cartEmail = Cookies.get('cartEmail')
        if (cartEmail) {
            defaultEmail.value = cartEmail
        }
    }
    setDefaultEmail()
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionEmailChoice {
    &-editForm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &-field {
        flex-grow: 1;
        margin-right: 10px;
    }

    &-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: $border-radius-xsmall;
        background: $color-brandPrimary;
        color: $color-lightest;

        &Icon {
            font-size: 24px;
        }
    }

    &-editEmailButton {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        color: $color-brandPrimary;
    }

    &-editEmailButtonIcon {
        font-size: 24px;
    }
}
</style>
