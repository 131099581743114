import { isEmpty } from 'global-utils'
import i18n from 'i18n'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import store from '@/StoreWeb/store/store'
import config from 'config'

const debuggingManager = DebuggingManager.getInstance()

export default class {
    constructor (
        id
    ) {
        this._amountExcludingTaxes = 0
        this._amountIncludingTaxes = 0
        this._currency = 'EUR'
        this._description = ''
        this._documents = []
        this._endDate = ''
        this._externalId = ''
        this._expressPurchase = false
        this._providerNetworkLabel = ''

        this._fareRule = ''
        this._fareExcludingTaxes = 0
        this._vatRate = 0
        this._vatAmount = 0
        this._fareIncludingTaxes = ''
        this._fareRecurringIncludingTaxes = ''
        this._fareTotalRecurringIncludingTaxes = ''
        this._fareTotalIncludingTaxes = ''
        this._fareRecurringPeriod = ''
        this._fareRecurringPeriodCount = ''

        this._id = id
        this._image = ''
        this._imageAlt = ''
        this._index = 1
        this._keywords = []
        this._level = 1
        this._maxNumber = 1
        this._minNumber = 1
        this._modalType = null
        this._name = ''
        this._paymentModes = []

        this._preauthorizedAmount = 0
        this._productParameters = []
        this._preSetProductParameters = []
        // Possible ids for productType : PRODUCT, FAREMEDIA, FAREMEDIA_RIGHT, FINE, MODAL_COUNTER, MODALPLAN
        this._productType = null
        this._provider = null
        this._quantity = 1
        this._saleableUnit = {
            id: 'UNIT',
            label: "Vente à l'unité" // TODO : Use translation variable
        }
        this._saleOptions = []
        this._shortDescription = ''
        this._startDate = null
        this._status = ''
        this._subProducts = []
        this._termsOfServiceUrl = ''
        this._userAuthModes = []
        this._variations = []
        this._version = 1
        this._weeeTaxApplicable = false
        this._requiredFareProfiles = []

        this._seoTitle = ''
        this._seoMetaDescription = ''
        this._seoImage = ''
        this._seoImageAlt = ''
    }

    get amountExcludingTaxes () {
        return this._amountExcludingTaxes
    }

    set amountExcludingTaxes (amountExcludingTaxes) {
        this._amountExcludingTaxes = amountExcludingTaxes
    }

    get amountIncludingTaxes () {
        return this._amountIncludingTaxes
    }

    set amountIncludingTaxes (amountIncludingTaxes) {
        this._amountIncludingTaxes = amountIncludingTaxes
    }

    get currency () {
        return this._currency
    }

    set currency (currency) {
        this._currency = currency
    }

    get description () {
        return this._description
    }

    set description (description) {
        this._description = description
    }

    get documents () {
        return this._documents
    }

    set documents (documents) {
        this._documents = documents
    }

    get endDate () {
        return this._endDate
    }

    set endDate (endDate) {
        this._endDate = endDate
    }

    get expressPurchase () {
        return this._expressPurchase
    }

    set expressPurchase (expressPurchase) {
        this._expressPurchase = expressPurchase
    }

    get externalId () {
        return this._externalId
    }

    set externalId (externalId) {
        this._externalId = externalId
    }

    get fareRule () {
        return this._fareRule
    }

    set fareRule (fareRule) {
        this._fareRule = fareRule
    }

    get fareExcludingTaxes () {
        return this._fareExcludingTaxes
    }

    set fareExcludingTaxes (fareExcludingTaxes) {
        this._fareExcludingTaxes = fareExcludingTaxes
    }

    get vatRate () {
        return this._vatRate
    }

    set vatRate (vatRate) {
        this._vatRate = vatRate
    }

    get vatAmount () {
        return this._vatAmount
    }

    set vatAmount (vatAmount) {
        this._vatAmount = vatAmount
    }

    get fareIncludingTaxes () {
        return this._fareIncludingTaxes
    }

    set fareIncludingTaxes (fareIncludingTaxes) {
        this._fareIncludingTaxes = fareIncludingTaxes
    }

    get fareRecurringIncludingTaxes () {
        return this._fareRecurringIncludingTaxes
    }

    set fareRecurringIncludingTaxes (fareRecurringIncludingTaxes) {
        this._fareRecurringIncludingTaxes = fareRecurringIncludingTaxes
    }

    get fareTotalRecurringIncludingTaxes () {
        return this._fareTotalRecurringIncludingTaxes
    }

    set fareTotalRecurringIncludingTaxes (fareTotalRecurringIncludingTaxes) {
        this._fareTotalRecurringIncludingTaxes = fareTotalRecurringIncludingTaxes
    }

    get fareTotalIncludingTaxes () {
        return this._fareTotalIncludingTaxes
    }

    set fareTotalIncludingTaxes (fareTotalIncludingTaxes) {
        this._fareTotalIncludingTaxes = fareTotalIncludingTaxes
    }

    get fareRecurringPeriod () {
        return this._fareRecurringPeriod
    }

    set fareRecurringPeriod (fareRecurringPeriod) {
        this._fareRecurringPeriod = fareRecurringPeriod
    }

    get fareRecurringPeriodCount () {
        return this._fareRecurringPeriodCount
    }

    set fareRecurringPeriodCount (fareRecurringPeriodCount) {
        this._fareRecurringPeriodCount = fareRecurringPeriodCount
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get image () {
        return this._image
    }

    set image (image) {
        this._image = image
    }

    get imageAlt () {
        return this._imageAlt
    }

    set imageAlt (imageAlt) {
        this._imageAlt = imageAlt
    }

    get index () {
        return this._index
    }

    set index (index) {
        this._index = index
    }

    get keywords () {
        return this._keywords
    }

    set keywords (keywords) {
        this._keywords = keywords
    }

    get level () {
        return this._level
    }

    set level (level) {
        this._level = level
    }

    get maxNumber () {
        return this._maxNumber
    }

    set maxNumber (maxNumber) {
        this._maxNumber = maxNumber
    }

    get minNumber () {
        return this._minNumber
    }

    set minNumber (minNumber) {
        this._minNumber = minNumber
    }

    get modalType () {
        return this._modalType
    }

    set modalType (modalType) {
        this._modalType = modalType
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get paymentModes () {
        return this._paymentModes
    }

    set paymentModes (paymentModes) {
        this._paymentModes = paymentModes
    }

    get preauthorizedAmount () {
        return this._preauthorizedAmount
    }

    set preauthorizedAmount (preauthorizedAmount) {
        this._preauthorizedAmount = preauthorizedAmount
    }

    get productParameters () {
        return this._productParameters
    }

    set productParameters (productParameters) {
        this._productParameters = productParameters
    }

    get preSetProductParameters () {
        return this._preSetProductParameters
    }

    set preSetProductParameters (preSetProductParameters) {
        this._preSetProductParameters = preSetProductParameters
    }

    get productType () {
        return this._productType
    }

    set productType (productType) {
        this._productType = productType
    }

    get provider () {
        return this._provider
    }

    set providerNetworkLabel (providerNetworkLabel) {
        this._providerNetworkLabel = providerNetworkLabel
    }

    get providerNetworkLabel () {
        return this._providerNetworkLabel
    }

    set provider (provider) {
        this._provider = provider
    }

    get quantity () {
        return this._quantity
    }

    set quantity (quantity) {
        this._quantity = quantity
    }

    get saleableUnit () {
        return this._saleableUnit
    }

    set saleableUnit (saleableUnit) {
        this._saleableUnit = saleableUnit
    }

    get saleOptions () {
        return this._saleOptions
    }

    set saleOptions (saleOptions) {
        this._saleOptions = saleOptions
    }

    get shortDescription () {
        return this._shortDescription
    }

    set shortDescription (shortDescription) {
        this._shortDescription = shortDescription
    }

    get startDate () {
        return this._startDate
    }

    set startDate (startDate) {
        this._startDate = startDate
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get subProducts () {
        return this._subProducts
    }

    set subProducts (subProducts) {
        this._subProducts = subProducts
    }

    get termsOfServiceUrl () {
        return this._termsOfServiceUrl
    }

    set termsOfServiceUrl (termsOfServiceUrl) {
        this._termsOfServiceUrl = termsOfServiceUrl
    }

    get userAuthModes () {
        return this._userAuthModes
    }

    set userAuthModes (userAuthModes) {
        this._userAuthModes = userAuthModes
    }

    get variations () {
        return this._variations
    }

    set variations (variations) {
        this._variations = variations
    }

    get version () {
        return this._version
    }

    set version (version) {
        this._version = version
    }

    get weeeTaxApplicable () {
        return this._weeeTaxApplicable
    }

    set weeeTaxApplicable (weeeTaxApplicable) {
        this._weeeTaxApplicable = weeeTaxApplicable
    }

    get requiredFareProfiles () {
        return this._requiredFareProfiles
    }

    set requiredFareProfiles (requiredFareProfiles) {
        this._requiredFareProfiles = requiredFareProfiles
    }

    get seoTitle () {
        return this._seoTitle
    }

    set seoTitle (seoTitle) {
        this._seoTitle = seoTitle
    }

    get seoMetaDescription () {
        return this._seoMetaDescription
    }

    set seoMetaDescription (seoMetaDescription) {
        this._seoMetaDescription = seoMetaDescription
    }

    get seoImage () {
        return this._seoImage
    }

    set seoImage (seoImage) {
        this._seoImage = seoImage
    }

    get seoImageAlt () {
        return this._seoImageAlt
    }

    set seoImageAlt (seoImageAlt) {
        this._seoImageAlt = seoImageAlt
    }

    getFare () {
        if (this.fareIncludingTaxes !== this.amountIncludingTaxes && this.amountIncludingTaxes > this.fareIncludingTaxes) {
            return this.amountIncludingTaxes
        }
        return this.fareIncludingTaxes
    }

    getTargets () {
        const targets = []
        const settledIcons = []

        for (const saleOption of this.saleOptions) {
            const icon = this.getTargetIcon(saleOption.target.id)
            const label = this.getTargetLabel(saleOption.target.id)

            if (icon && settledIcons.indexOf(icon) === -1) {
                settledIcons.push(icon)
                targets.push({
                    defaultLabel: saleOption.target.label,
                    icon,
                    id: saleOption.target.id,
                    label
                })
            }
        }

        return targets
    }

    getTargetIcon (targetId) {
        switch (targetId) {
            case 'CARD':
                return 'Card'
            case 'QRCODE':
                return 'QrCode'
            case 'DEMATERIALIZED_CARD':
                return 'Phone'
        }

        return null
    }

    getTargetLabel (targetId) {
        return i18n.global.t(`sale_option:target:${targetId.toLowerCase()}`)
    }

    getProvider () {
        if (this.modalType === 'MODALPRODUCT' && this.saleOptions.length) {
            return this.saleOptions[0]?.provider?.id || null
        }

        return null
    }

    getCompatibleSaleOptions (options) {
        const compatibleSaleOptions = []
        let validSaleOption

        this.saleOptions.forEach(saleOption => {
            validSaleOption = true
            for (const i in options) {
                if (!(!isEmpty(saleOption[i]) && saleOption[i].id === options[i])) {
                    validSaleOption = false
                }
            }
            if (validSaleOption) {
                compatibleSaleOptions.push(saleOption)
            }
        })

        return compatibleSaleOptions
    }

    getBaseFlowStepParams () {
        const flowStep = {
            label: '',
            level: this.level,
            product: this
        }
        return flowStep
    }

    getAuthenticationModeSelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.authenticationModes = this.getUserAuthModes()
        const messageStep = config.features.modal_auth_guest_mode_activated && flowStep.authenticationModes.includes('EMAILMANDATORY')
            ? 'add_to_cart_flow:authentication_mode_description_guest:title'
            : 'add_to_cart_flow:authentication_mode_description:title'
        flowStep.type = 'authenticationMode'
        flowStep.label = i18n.global.t(messageStep) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.selectedAuthenticationMode = null
        flowStep.selectedAuthenticationModeParameters = null
        return flowStep
    }

    getQuantitySelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.type = 'quantity'
        flowStep.label = i18n.global.t('add_to_cart_flow:quantity_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.minNumber = this.minNumber
        flowStep.maxNumber = this.maxNumber
        flowStep.quantity = this.quantity
        flowStep.selectedQuantity = this.quantity
        return flowStep
    }

    getVariationSelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.type = 'variation'
        flowStep.label = i18n.global.t('add_to_cart_flow:variation_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.options = []
        this.variations.forEach(item => {
            flowStep.options.push({
                id: item.id,
                name: item.name,
                fare: item.getFare()
            })
        })
        flowStep.selectedVariation = null
        return flowStep
    }

    getVariationParamsSelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.type = 'dynamic'
        flowStep.label = 'Etape dynamique (dépendante de la variation choisie et de sa configuration) pour ' + this.name + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.product = null
        flowStep.steps = []
        flowStep.stepsLevel = flowStep.level + 1
        return flowStep
    }

    getDocumentsSelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.type = 'documents'
        flowStep.label = i18n.global.t('add_to_cart_flow:documents_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.documents = this.documents
        flowStep.providedDocuments = null
        return flowStep
    }

    getProductParametersSelectorStep (currentFlow) {
        const totalProductParameters = this.productParameters.length
        this.productParameters.forEach((productParameter, index) => {
            const flowStep = this.getBaseFlowStepParams()
            flowStep.type = 'productParameter'
            switch (productParameter.id) {
                case 'START_VALIDITY_DATE':
                    flowStep.label = i18n.global.t('add_to_cart_flow:start_validity_date_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'END_VALIDITY_DATE':
                    flowStep.label = i18n.global.t('add_to_cart_flow:end_validity_date_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'ADDRESS':
                    flowStep.label = i18n.global.t('add_to_cart_flow:address_form:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'CARDID':
                    flowStep.label = i18n.global.t('add_to_cart_flow:card_id:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'FINEFORM1':
                    flowStep.label = i18n.global.t('add_to_cart_flow:fine_form1:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'LINES':
                    flowStep.label = i18n.global.t('add_to_cart_flow:lines_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'NAMEDCARDFORM':
                    flowStep.label = i18n.global.t('add_to_cart_flow:named_card_form:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'NB_TRAVELLERS':
                    flowStep.label = i18n.global.t('add_to_cart_flow:nb_travellers_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'ODZONE_ROUTE':
                    flowStep.label = i18n.global.t('add_to_cart_flow:origin_destination_zone_route_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'ORIGIN_DESTINATION':
                    flowStep.label = i18n.global.t('add_to_cart_flow:origin_destination_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'PERSONALCARDFORM':
                    flowStep.label = i18n.global.t('add_to_cart_flow:personnal_card_form:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'TRANSPORT_CLASS':
                    flowStep.label = i18n.global.t('add_to_cart_flow:transport_class_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'ZONE_ADJACENT':
                    flowStep.label = i18n.global.t('add_to_cart_flow:zone_adjacent_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                case 'ZONE':
                    flowStep.label = i18n.global.t('add_to_cart_flow:zone_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
                default:
                    flowStep.label = productParameter.label + ' pour ' + this.name + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
                    break
            }
            flowStep.id = productParameter.id
            flowStep.productParameter = productParameter
            flowStep.productParameterValues = null
            flowStep.isLastProductParameter = index === (totalProductParameters - 1)
            currentFlow.push(flowStep)
        })
    }

    getSaleOptionSelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.type = 'saleOption'
        // flowStep.label = 'Choisissez une option de vente pour ' + this.name
        flowStep.label = i18n.global.t('add_to_cart_flow:sale_option_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.saleOptions = this.saleOptions
        flowStep.selectedSaleOption = this.saleOptions.length === 1 ? this.saleOptions[0] : null
        flowStep.selectedSaleOptionParameters = null
        return flowStep
    }

    getTermsApprovalSelectorStep () {
        const flowStep = this.getBaseFlowStepParams()
        flowStep.type = 'termsApproval'
        // flowStep.label = 'Vous devez approuver les conditions générales d\'utilisation pour ' + this.name
        flowStep.label = i18n.global.t('add_to_cart_flow:terms_approval_selector:title').replace('%productName%', this.name) + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + this.id + ')' : '')
        flowStep.termsOfServiceUrl = this.termsOfServiceUrl
        flowStep.isApproved = true
        return flowStep
    }

    getSubProductsParamsSelectorStep (currentFlow, params) {
        this.subProducts.forEach(subProduct => {
            const flowStep = this.getBaseFlowStepParams()
            flowStep.type = 'subProductFlow'
            flowStep.label = 'Configuration du sous-produit ' + subProduct.name + this.name + (debuggingManager.debugInformationsDisplay > 1 ? ' (#' + subProduct.id + ') ' + this.name + ' (#' + this.id + ')' : '')
            flowStep.subProduct = subProduct
            flowStep.steps = []
            flowStep.stepsLevel = flowStep.level + 1
            subProduct.getProductSteps(flowStep.steps, subProduct.getAddToCartFlowConfig(params))
            currentFlow.push(flowStep)
        })
    }

    getAddToCartFlowConfig (params) {
        const flowConfig = {
            documentsSelector: !isEmpty(this.documents),
            id: this.id,
            level: this.level,
            product: this,
            authenticationModeSelector: this.modalType === 'MODALPRODUCT' && (!store.state.userModule.isAuthenticated || params?.forceAuthenticationModeSelector),
            productParametersSelector: !isEmpty(this.productParameters) ? !isEmpty(this.productParameters) : !isEmpty(this.preSetProductParameters),
            quantitySelector: this.saleableUnit.id === 'UNIT' && this.minNumber !== this.maxNumber && this.minNumber !== 0,
            variationSelector: !isEmpty(this.variations),
            subProductConfigSelector: !isEmpty(this.subProducts),
            saleOptionSelector: !isEmpty(this.saleOptions),
            termsApproval: !isEmpty(this.termsOfServiceUrl),
            version: this.version
        }

        if (!isEmpty(this.subProducts)) {
            flowConfig.subProductsFlowsConfigs = []
            this.subProducts.forEach(subProduct => {
                flowConfig.subProductsFlowsConfigs.push(subProduct.getAddToCartFlowConfig(params))
            })
        }

        return flowConfig
    }

    getProductSteps (currentFlow, productFlowConfig, params) {
        if (productFlowConfig.variationSelector) {
            currentFlow.push(productFlowConfig.product.getVariationSelectorStep())
            currentFlow.push(productFlowConfig.product.getVariationParamsSelectorStep())
        }
        if (productFlowConfig.authenticationModeSelector) {
            currentFlow.push(productFlowConfig.product.getAuthenticationModeSelectorStep())
        }
        if (productFlowConfig.saleOptionSelector) {
            currentFlow.push(productFlowConfig.product.getSaleOptionSelectorStep())
        }
        if (productFlowConfig.productParametersSelector) {
            productFlowConfig.product.getProductParametersSelectorStep(currentFlow)
        }
        if (productFlowConfig.documentsSelector) {
            currentFlow.push(productFlowConfig.product.getDocumentsSelectorStep())
        }
        if (productFlowConfig.quantitySelector) {
            currentFlow.push(productFlowConfig.product.getQuantitySelectorStep())
        }
        if (productFlowConfig.termsApproval) {
            currentFlow.push(productFlowConfig.product.getTermsApprovalSelectorStep())
        }
        if (!isEmpty(productFlowConfig.subProductsFlowsConfigs)) {
            productFlowConfig.product.getSubProductsParamsSelectorStep(currentFlow, params)
        }
    }

    buildStepsFromFlowConfig (addToCartFlowConfig, params, currentFlow) {
        if (typeof currentFlow === 'undefined') {
            currentFlow = []
        }
        if (typeof addToCartFlowConfig === 'object') {
            this.getProductSteps(currentFlow, addToCartFlowConfig, params)
        }
        return currentFlow
    }

    getAddToCartFlow (params) {
        const addToCartFlowConfig = this.getAddToCartFlowConfig(params)
        return this.buildStepsFromFlowConfig(addToCartFlowConfig, params)
    }

    getSaleOptionsReport () {
        const saleOptionsReport = {
            invalid: [],
            valid: []
        }
        this.saleOptions.forEach(saleOption => {
            if (saleOption.isValid()) {
                saleOptionsReport.valid.push(saleOption)
            } else {
                saleOptionsReport.invalid.push(saleOption)
            }
        })

        return saleOptionsReport
    }

    getUserAuthModes () {
        const userAuthModes = []

        this.saleOptions.forEach(saleOption => {
            if (!isEmpty(saleOption.userAuthMode) && userAuthModes.indexOf(saleOption.userAuthMode.id) === -1) {
                userAuthModes.push(saleOption.userAuthMode.id)
            }
        })

        return userAuthModes
    }

    hasMultipleUserAuthModes () {
        return this.getUserAuthModes().length > 1
    }

    hasToLoginOnAddToCart () {
        const userAuthModes = this.getUserAuthModes()

        return userAuthModes.length === 1 && userAuthModes.indexOf('USERACCOUNT') !== -1
    }

    getSaleOptionsForUserAuthMode () {
        return this.saleOptions.filter(saleOption => (
            (saleOption.userAuthMode.id === 'USERACCOUNT' && store.state.userModule.isAuthenticated) ||
            (saleOption.userAuthMode.id === 'EMAILMANDATORY' && !store.state.userModule.isAuthenticated)
        ))
    }

    requiresCardReader (saleOption) {
        return saleOption.deliveryMode.id === 'CARDREADER_MANDATORY'
    }

    isCardReaderMandatory () {
        const saleOptions = this.getSaleOptionsForUserAuthMode()

        if (saleOptions.length > 0) {
            return !saleOptions.some(saleOption => !this.requiresCardReader(saleOption))
        }

        return false
    }

    isFareMedia () {
        if (this.productType && this.productType.id === 'FAREMEDIA') {
            return true
        } else if (!isEmpty(this.subProducts)) {
            this.subProducts.forEach(product => {
                if (product.modalType === 'MODALPRODUCT' && product.productType.id === 'FAREMEDIA') {
                    return true
                }
            })
        }
        return false
    }

    toJSON () {
        return {
            amountExcludingTaxes: this.amountExcludingTaxes,
            amountIncludingTaxes: this.amountIncludingTaxes,
            currency: this.currency,
            description: this.description,
            documents: this.documents,
            endDate: this.endDate,
            externalId: this.externalId,
            expressPurchase: this.expressPurchase,
            fare: this.fare,
            fareExcludingTaxes: this.fareExcludingTaxes,
            fareFrom: this.fareFrom,
            fareIncludingTaxes: this.fareIncludingTaxes,
            id: this.id,
            image: this.image,
            imageAlt: this.imageAlt,
            index: this.index,
            keywords: this.keywords,
            level: this.level,
            maxNumber: this.maxNumber,
            minNumber: this.minNumber,
            modalType: this.modalType,
            name: this.name,
            paymentModes: this.paymentModes,
            preauthorizedAmount: this.preauthorizedAmount,
            productParameters: this.productParameters,
            preSetProductParameters: this.preSetProductParameters,
            productType: this.productType,
            provider: this.provider,
            providerNetworkLabel: this.providerNetworkLabel,
            quantity: this.quantity,
            saleableUnit: this.saleableUnit,
            saleOptions: this.saleOptions,
            shortDescription: this.shortDescription,
            startDate: this.startDate,
            status: this.status,
            subProducts: this.subProducts,
            taxes: this.taxes,
            termsOfServiceUrl: this.termsOfServiceUrl,
            userAuthModes: this.userAuthModes,
            variations: this.variations,
            vatAmount: this.vatAmount,
            vatRate: this.vatRate,
            version: this.version,
            weeeTaxApplicable: this.weeeTaxApplicable,
            requiredFareProfiles: this.requiredFareProfiles,
            seoTitle: this.seoTitle,
            seoMetaDescription: this.seoMetaDescription,
            seoImage: this.seoImage,
            seoImageAlt: this.seoImageAlt
        }
    }
}
