import i18n from 'i18n'

export const getMimeCorrelation = (mimeType) => {
    switch (mimeType) {
        case 'image/jpeg':
            return 'jpg/jpeg'
        case 'image/png':
            return 'png'
        case 'application/pdf':
            return 'pdf'
        default:
            return ''
    }
}

// maxSize is based in octets
export const getLitteralSize = (maxSize) => {
    if (maxSize < 1024) {
        return i18n.global.t('file_size:bytes').replace('%size%', maxSize)
    } else if (maxSize < 1024 * 1024) {
        return i18n.global.t('file_size:kb').replace('%size%', Math.ceil(maxSize / 1024).toString())
    } else if (maxSize < 1024 * 1024 * 1024) {
        return i18n.global.t('file_size:mb').replace('%size%', Math.ceil(maxSize / (1024 * 1024)).toString())
    } else {
        return i18n.global.t('file_size:gb').replace('%size%', Math.ceil(maxSize / (1024 * 1024 * 1024)).toString())
    }
}
// fileSize and maxSize is based in octets
export const isAuthorizedSize = (fileSize, maxSize) => {
    return fileSize <= maxSize
}

export const getFileInstructions = (mimeTypes, maxSize, multiple = false) => {
    const mimeCorrelations = [...new Set(mimeTypes.map(getMimeCorrelation))]
    const mimeTypeString = `${i18n.global.t('forms:fileinput:mimetypes')}: ` + mimeCorrelations
        .slice(0, -1)
        .join(', ') +
        (mimeCorrelations.length > 1 ? ' ' + i18n.global.t('miscellaneous:or') + ' ' : '') +
        mimeCorrelations.slice(-1)

    const sizeString = `${i18n.global.t('forms:fileinput:max')}: ${getLitteralSize(maxSize)}`
    const instructions = [mimeTypeString, sizeString]

    if (multiple) {
        instructions.push(i18n.global.t('forms:fileinput:piece'))
    }

    return instructions.join('<br />')
}

export const getFileSizeExceded = (name, size, maxSize) => {
    return i18n.global.t('forms:fileinput:max_size_exceded').replace('%name%', name).replace('%size%', getLitteralSize(size)).replace('%max_size%', getLitteralSize(maxSize))
}

export const getMimeTypesExtension = (extensions) => {
    const extensionsToMimeTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf'
    }
    return extensions.map(extension => {
        const lowerCaseExtension = extension.toLowerCase()
        return extensionsToMimeTypes[lowerCaseExtension]
    }).filter(mimeType => mimeType)
}

export default {
    getMimeCorrelation,
    getLitteralSize,
    getFileInstructions,
    getFileSizeExceded,
    getMimeTypesExtension,
    isAuthorizedSize
}
