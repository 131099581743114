<template>
    <div class="ProductDetailsFareAndActions">
        <div class="d-flex flex-wrap justify-space-between gap-4">
            <ul
                v-if="hasTargetIcons"
                class="ProductDetailsFareAndActions-targets d-flex flex-column align-start w-100 gap-1 mb-6"
            >
                <li
                    v-for="target in targetIcons"
                    :key="target.id"
                    class="d-flex align-center gap-2 text-body-2"
                >
                    <icomoon-icon
                        :name="target.icon"
                        class="ProductDetailsFareAndActions-targetIcon"
                    />
                    {{ target.label }}
                </li>
            </ul>
            <price
                :display-weee-tax="true"
                :product="productDetails"
                size="XLarge"
                class="ProductDetailsFareAndActions-fare order-2 order-md-1 mb-6"
            />
            <div class="ProductDetailsFareAndActions-buttonContainer order-3">
                <v-btn
                    v-if="showAddToCart"
                    class="ProductDetailsFareAndActions-button mb-8 text-body-1 font-weight-bold"
                    color="primary"
                    elevation="0"
                    size="large"
                    @click="addCartAction"
                >
                    {{ $t('button:add_to_cart') }}
                </v-btn>
            </div>
        </div>
        <p
            v-if="showExpressPurchaseMessage"
            class="ProductDetailsFareAndActions-quickPurchase d-flex align-center gap-4 mt-0 mb-6"
        >
            <v-icon class="ProductDetailsFareAndActions-quickPurchaseIcon">
                mdi-clock-fast
            </v-icon>
            <span
                v-html="$sanitize(quickPurchaseMessage)"
            />
        </p>
        <span
            v-if="productDetails.isCardReaderMandatory()"
            class="ProductDetailsFareAndActions-cardReaderMandatory"
        >
            <icomoon-icon
                :layersCount="3"
                name="CardReader"
                class="ProductDetailsFareAndActions-cardReaderMandatoryIcon"
            />
            <span
                v-html="$sanitize($t('product_details:card_reader_mandatory'))"
            />
        </span>
        <product-required-fare-profiles
            v-else-if="showRequiredFareProfilesModal"
            :required-fare-profiles="productDetails.requiredFareProfiles"
            :selected-fare-media="selectedFareMedia"
        />
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import Price from '@/StoreWeb/components/common/Price'
import ProductRequiredFareProfiles from '@/StoreWeb/components/product/ProductRequiredFareProfiles'
import { computed } from 'vue'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import addCartFlowMixins from '@/StoreWeb/js/mixins/add-cart-flow-utils'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { checkHasToCreateMspAccount, triggerAddAccountEvent } from '@/StoreWeb/js/composables/msp-account-utils'
import * as catalogMutationTypes from '@/StoreWeb/store/modules/catalog/mutation-types'

const store = useStore()
const { t } = useI18n()
const { storeAddCartFlow, triggerLogin } = addCartFlowMixins()
const { isAuthenticated } = useLogin()

const quickPurchaseMessage = t('quick_purchase:explanations').replace(/\\n/g, '<br>')

const targetIcons = computed(() => productDetails.value.getTargets())
const hasTargetIcons = computed(() => targetIcons.value.length > 0)
const getUserStatus = computed(() => store.getters.getUserStatus)
const getHasSelectedFareMedia = computed(() => {
    return selectedFareMedia.value && !isEmpty(selectedFareMedia.value) && selectedFareMedia.value.id !== undefined
})
const productDetails = computed(() => store.state.catalogModule.productDetails)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const showExpressPurchaseMessage = computed(() => {
    return showAddToCart.value && productDetails.value.expressPurchase
})
const showRequiredFareProfilesModal = computed(() => {
    return productDetails.value.requiredFareProfiles && productDetails.value.requiredFareProfiles.length > 0
})
const showAddToCart = computed(() => {
    return productDetails.value.saleableUnit.id !== 'NON_SALEABLE' &&
        (
            getHasSelectedFareMedia.value !== true ||
            (
                getHasSelectedFareMedia.value === true &&
                productDetails.value.requiredFareProfiles.length === 0
            )
        )
})

function addCartAction () {
    if (isAuthenticated.value) {
        const { hasToCreateMspAccount, provider } = checkHasToCreateMspAccount(productDetails.value)

        if (!userPendingRequests.value.getWallet && getUserStatus.value === 'BLOCKED_PAYMENT_REQUIRED') {
            emitter.emit('showAlertModal', {
                messageHTML: t('wallet:blocked_payment_required:message'),
                title: t('wallet:blocked_payment_required:title')
            })

            return
        }

        if (hasToCreateMspAccount) {
            store.commit(catalogMutationTypes.SET_PRODUCT_TO_ADD_ONCE_ACCOUNT_FINALIZED, productDetails.value)
            triggerAddAccountEvent(provider)

            return
        }

        emitter.emit('showProductModal', productDetails.value)

        return
    }

    if ((productDetails.value.modalType === 'MODALPRODUCT' || productDetails.value.modalType === 'MODALPRODUCTGROUP') && !isEmpty(productDetails.value.getUserAuthModes())) {
        if (productDetails.value.hasToLoginOnAddToCart()) {
            storeAddCartFlow({
                mode: 'PRODUCT_DETAILS',
                product: productDetails.value
            })
        }

        emitter.emit('showProductModal', productDetails.value)

        return
    }

    storeAddCartFlow({
        mode: 'PRODUCT_DETAILS',
        product: productDetails.value
    })

    triggerLogin({
        callback: emitter.emit('hideProductModal', { keep: true })
    })
}
</script>

<style scoped lang="scss">
@import 'globalScss';

.ProductDetailsFareAndActions {
    &-targets {
        list-style: none;
    }

    &-targetIcon {
        font-size: $font-xlarge;
        color: $color-brandSecondary;
    }

    &-quickPurchase {
        font-size: $font-xsmall;
        color: $color-brandSecondary;
    }

    &-quickPurchaseIcon.v-icon {
        font-size: $font-xlarge;
        color: inherit;
    }

    &-buttonContainer {
        width: 100%;
    }

    &-cardReaderMandatory {
        display: flex;
        gap: $s2;
        padding: $s4;
        border-radius: $border-radius-medium;
        background: $color-brandSecondaryLightBackground;
        font-weight: bold;
        color: $color-brandSecondary;
    }

    &-cardReaderMandatoryIcon {
        margin-top: 2px;
        font-size: $font-medium;
    }

    &-requiredFareProfiles {
        font-size: $font-xsmall;
        line-height: $font-large;
    }
}
</style>
