import Wallet from '@/StoreWeb/models/user/wallet/Wallet'
import ProviderNormalizer from '@/StoreWeb/normalizers/user/wallet/ProviderNormalizerSingleton'
import UserPlanNormalizer from '@/StoreWeb/normalizers/user/wallet/UserPlanNormalizerSingleton'
import UserModalCounterNormalizer from '@/StoreWeb/normalizers/user/wallet/UserModalCounterNormalizerSingleton'
import AssociatedAccountNormalizer from '@/StoreWeb/normalizers/user/wallet/AssociatedAccountNormalizerSingleton'
import LoginManager from 'user/Login/managers/LoginManager'
import { isEmpty } from 'global-utils'
import RecipientUser from '@/StoreWeb/models/user/RecipientUser'
import store from '@/StoreWeb/store/store'
import config from 'config'

export default class {
    constructor () {
        this._associatedAccountNormalizer = AssociatedAccountNormalizer.getInstance()
        this._providerNormalizer = ProviderNormalizer.getInstance()
        this._userModalCounterNormalizer = UserModalCounterNormalizer.getInstance()
        this._userPlanNormalizer = UserPlanNormalizer.getInstance()
    }

    get associatedAccountNormalizer () {
        return this._associatedAccountNormalizer
    }

    get providerNormalizer () {
        return this._providerNormalizer
    }

    get userModalCounterNormalizer () {
        return this._userModalCounterNormalizer
    }

    get userPlanNormalizer () {
        return this._userPlanNormalizer
    }

    normalize (item) {
        const wallet = new Wallet()

        if (!isEmpty(item.associatedAccounts)) {
            wallet.associatedAccounts = this.associatedAccountNormalizer.normalizeArray(item.associatedAccounts)
        }
        wallet.lastSelectedFareMediaId = item.lastSelectedFaremediaId ?? ''
        if (!isEmpty(item.modalCounters)) {
            wallet.counters = this.userModalCounterNormalizer.normalizeArray(item.modalCounters)
        }
        if (!isEmpty(item.providers)) {
            const recipientUser = new RecipientUser()
            let userInfos

            if (config.web_harmony.header) {
                userInfos = store.state.userModule.userInfos
            } else {
                userInfos = LoginManager.userInfos
            }

            if (!isEmpty(userInfos)) {
                recipientUser.email = userInfos.email
                recipientUser.firstName = userInfos.firstName
                recipientUser.lastName = userInfos.lastName
                recipientUser.userId = userInfos.externalUserId
            }

            const params = {
                recipientUser
            }
            wallet.providers = this.providerNormalizer.normalizeArray(item.providers, params)
        }
        if (!isEmpty(item.userPlans)) {
            wallet.plans = this.userPlanNormalizer.normalizeArray(item.userPlans)
        }
        if (!isEmpty(item.userStatus)) {
            wallet.userStatus = item.userStatus
        }

        return wallet
    }
}
