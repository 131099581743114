import { createApp } from 'vue'
import i18n from 'i18n'
import clickOutside from '@/StoreWeb/utils/vue-directive-clickOutside'
import App from './App'
import router from '@/router/router'
import store from '@/StoreWeb/store/store'
import AccountLayout from '@/StoreWeb/layouts/AccountLayout'
import DefaultLayout from '@/StoreWeb/layouts/DefaultLayout'
import * as loginActionTypes from 'user/Login/store/action-types'
import config from 'config'
import mainInit from '@/StoreWeb/js/main-init'
import vuetify from '@/plugin/vuetify'
import '@/StoreWeb/assets/scss/global.scss'
import '@instant-system/web-harmony-widget/style.css'
import '@/StoreWeb/js/mixins/global-prototypes-enhancements'
import 'typeface-lato'
import 'material-icons/iconfont/material-icons.css'
import moment from 'moment'
import 'vuetify/dist/vuetify.min.css'
import Vue3Sanitize from 'vue-3-sanitize'
import sanitizeConfig from '@/StoreWeb/js/sanitize-config'
import { importExternalComponents } from '@/StoreWeb/js/external-components-declarations'
import '@/StoreWeb/js/polyfills'
import versioningPlugin from '@/StoreWeb/js/versioning'

require('moment/locale/fr')

const app = createApp(App)

importExternalComponents(app)

app.config.globalProperties.$moment = moment
app.use(vuetify)
app.use(router)
app.use(store)
app.use(i18n)
app.use(versioningPlugin)
app.use(Vue3Sanitize, sanitizeConfig)

app.directive('click-outside', clickOutside)

app.component('AccountLayout', AccountLayout)
app.component('DefaultLayout', DefaultLayout)

app.config.globalProperties.$config = config

mainInit.init()
if (!config.web_harmony.header) {
    await store.dispatch(`login/${loginActionTypes.CHECK_STORED_TOKENS}`)
}

app.mount('#app')
