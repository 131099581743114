<template>
    <div class="PaymentSuccessWithDeliveryModes">
        <router-link
            v-if="validationAnimationFinished"
            :to="{ name: 'purchases' }"
            class="PaymentSuccessWithDeliveryModes-linkToOrders"
        >
            {{ $t('payment:success:go_to_orders') }}
        </router-link>
        <div class="PaymentSuccessWithDeliveryModes-validationContainer">
            <icomoon-icon
                :name="'Check'"
                class="PaymentSuccessWithDeliveryModes-icon"
            />
            <h1 class="PaymentSuccessWithDeliveryModes-title h1">
                {{ title }}
            </h1>
        </div>
        <template v-if="validationAnimationFinished">
            <payment-regular-card
                v-if="cartStatus === 'VALIDATION_ERROR'"
                v-bind="getValidationError"
            />
            <template v-else>
                <div
                    v-if="cardMandatoryOrOnlyOne"
                    class="PaymentSuccessWithDeliveryModes-cards PaymentSuccessWithDeliveryModes-cards--onlyOne"
                >
                    <payment-delivery-card
                        v-if="isCardMandatory(getFirstDeliveryMode)"
                        v-bind="getFirstDeliveryMode"
                        @open-support-reader-modal="openSupportReaderModal()"
                    />
                    <payment-regular-card
                        v-else
                        v-bind="getFirstDeliveryMode"
                        @open-support-reader-modal="openSupportReaderModal()"
                    />
                </div>
                <div
                    v-else
                    class="PaymentSuccessWithDeliveryModes-cards"
                >
                    <template
                        v-for="(deliveryMode, index) in getDeliveryModes"
                        :key="`payment_card_${index}`"
                    >
                        <payment-regular-card
                            v-if="!isCardMandatory(deliveryMode)"
                            v-bind="deliveryMode"
                            @open-support-reader-modal="openSupportReaderModal()"
                        />
                    </template>
                </div>
            </template>
        </template>
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="$t('card_reader:scan_modal:title')"
            :operationType="'WRITE'"
            @operation-finished="onSupportReaderOperationFinished"
        />
    </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'
import { isEmpty } from 'global-utils'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import PaymentDeliveryCard from '@/StoreWeb/components/payment/PaymentDeliveryCard'
import PaymentRegularCard from '@/StoreWeb/components/payment/PaymentRegularCard'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'

const props = defineProps({
    baskets: {
        type: Array,
        required: true
    },
    cartStatus: {
        type: String,
        required: true
    },
    id: {
        type: Number,
        required: true
    },
    title: {
        type: String,
        required: true
    }
})

const router = useRouter()
const store = useStore()

const showSupportReaderModal = ref(false)
const validationAnimationFinished = ref(false)

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const isMobileApp = computed(() => store.state.isMobileApp)
const cardMandatoryOrOnlyOne = computed(() => Object.keys(getDeliveryModes.value).length === 1 || ['CARDREADER_MANDATORY', 'NFC'].some(key => key in getDeliveryModes.value))
const getFirstDeliveryMode = computed(() => {
    const [, firstObject] = Object.entries(getDeliveryModes.value)[0]
    return firstObject
})

const getValidationError = computed(() => {
    return {
        description: 'payment:success:VALIDATION_ERROR:description',
        icon: 'VALIDATION_ERROR',
        id: props.id,
        title: 'payment:success:VALIDATION_ERROR:title',
        withButton: false
    }
})

const getDeliveryModes = computed(() => {
    const deliveryModes = {}
    let basketIsFareMedia = false

    props.baskets.forEach(basket => {
        if (basket.basket.type === 'REGULARIZATION') {
            deliveryModes.REGULARIZATION = {
                buttonLabel: 'payment:success:REGULARIZATION:button',
                deliveryMode: 'REGULARIZATION',
                description: 'payment:success:REGULARIZATION:description',
                icon: 'REGULARIZATION',
                id: props.id,
                params: {
                    providerId: basket.basket.providerId,
                    fareMediaId: basket.basket.fareMediaId
                },
                title: 'payment:success:REGULARIZATION:title'
            }

            return
        }

        if (basket.basket.type === 'FAREMEDIA') {
            basketIsFareMedia = true
        }
        basket.basket.products.forEach(product => {
            let deliveryMode
            if (basketIsFareMedia) {
                deliveryMode = 'POSTAL'
            } else if (['POSTAL', 'POSTALONPRODUCT', 'POSTAL_CLICKANDCOLLECT', 'POSTAL_CLICKANDCOLLECT_ONPRODUCT'].includes(product.item.deliveryMode)) {
                deliveryMode = 'POSTAL'
            } else if (['DISTRIBUTION_CARDREADER', 'CARDREADER_MANDATORY'].includes(product.item.deliveryMode) && isMobileApp.value) {
                deliveryMode = 'NFC'
            } else {
                deliveryMode = ['DISTRIBUTION_CARDREADER', 'CARDREADER_MANDATORY', 'REGULARIZATION'].includes(product.item.deliveryMode) ? product.item.deliveryMode : 'DEFAULT'
            }

            if (!deliveryModes[deliveryMode]) {
                deliveryModes[deliveryMode] = {
                    buttonLabel: ['DISTRIBUTION_CARDREADER', 'CARDREADER_MANDATORY', 'REGULARIZATION', 'NFC'].includes(deliveryMode) ? `payment:success:${deliveryMode}:button` : '',
                    deliveryMode,
                    description: deliveryMode === 'DEFAULT' ? '' : `payment:success:${deliveryMode}:description`,
                    icon: deliveryMode,
                    id: props.id,
                    params: {
                        providerId: product.item.providerId,
                        fareMediaId: product.item.fareMediaId
                    },
                    title: `payment:success:${deliveryMode}:title`
                }
            }
        })
    })

    const orderObject = 'NFC' in deliveryModes ? 'NFC' : 'CARDREADER_MANDATORY'
    return reorderDeliveryModes(deliveryModes, orderObject)
})

function isCardMandatory (card) {
    return ['CARDREADER_MANDATORY', 'NFC'].includes(card.deliveryMode)
}

function openSupportReaderModal () {
    isMobileApp.value ? useCardMobileReaderSDK() : showSupportReaderModal.value = true
}

function onSupportReaderOperationFinished () {
    showSupportReaderModal.value = false
}

function reorderDeliveryModes (deliveryModes, keyToMoveFirst) {
    if (Object.prototype.hasOwnProperty.call(deliveryModes, keyToMoveFirst)) {
        const { [keyToMoveFirst]: value, ...rest } = deliveryModes
        return { [keyToMoveFirst]: value, ...rest }
    }

    return deliveryModes
}

async function useCardMobileReaderSDK (deliverableFareMedias) {
    applicationSession.value.data.waitingForReloadingFareMedias = deliverableFareMedias
    const baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_CATALOG}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC}`
    applicationSession.value.sessionContext = {
        currentStep: baseUrl,
        resumeStepSuccess: `${baseUrl}&status=success`,
        resumeStepError: `${baseUrl}&status=error`
    }
    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)

    const externalLink = router.resolve({
        name: 'externalLinks',
        params: { action: externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC },
        query: { sessionId: applicationSession.value.id }
    })

    if (!isEmpty(externalLink)) {
        window.location.href = window.location.origin + externalLink.href
    }
}

onMounted(() => {
    setTimeout(() => {
        // Use this instead of css to avoid glitch
        validationAnimationFinished.value = true
    }, 3000)
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentSuccessWithDeliveryModes {
    display: flex;
    flex-direction: column;
    gap: $s10;
    position: relative;

    &-linkToOrders {
        position: absolute;
        z-index: 1;
        top: 0;
        left: $s4;
        font-weight: $fontWeight-defaultBold;
        color: $color-brandPrimary;
        transition: color .3s;

        &:hover,
        &:active {
            color: $color-brandPrimaryDark;
        }

        @media (min-width: $desktop-breakpoint) {
            right: $s4;
            left: auto;
        }
    }

    &-validationContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $s8;
        opacity: 0;
        transform: scale(2.2) translateY(calc(100px + $s8));
        animation: animation-validation 3s forwards;
    }

    &-icon {
        font-size: 64px;
        color: $color-brandSecondary;
    }

    &-title {
        margin: 0;
    }

    &-validationError {
        margin: 0 $s5;
    }

    &-cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: $s4;
        border-radius: $borderRadius-xlarge;
        background: $backgroundColor-greyGradient;
        opacity: 0;
        transform: translateY(50px);
        animation: animation-cards 1s forwards;

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
            gap: $s6;
            border-radius: 0;
            background: $color-white;

            &--onlyOne {
                grid-template-columns: 1fr;
            }
        }
    }
}

@keyframes animation-validation {
    0% {
        opacity: 0;
        transform: scale(2.2) translateY(calc(100px + $s8));
    }

    50% {
        opacity: 1;
        transform: scale(1) translateY(calc(100px + $s8));
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY($s8);
    }
}

@keyframes animation-cards {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
