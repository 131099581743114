import { ref } from 'vue'

export function useCardReader () {
    const showSupportReaderModal = ref(false)
    const supportId = ref(null)

    function openSupportReaderModal (support) {
        supportId.value = support ? support.id : null
        showSupportReaderModal.value = true
    }

    function onSupportReaderOperationFinished () {
        showSupportReaderModal.value = false
    }

    return {
        showSupportReaderModal,
        supportId,
        openSupportReaderModal,
        onSupportReaderOperationFinished
    }
}
