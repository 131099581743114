import { deleteCookie } from 'global-utils'
import { ref } from 'vue'

const version = ref(null)

export function useStorageManager () {
    const versionKey = 'storageVersion'

    function init (currentVersion) {
        version.value = currentVersion
        const savedVersion = localStorage.getItem(versionKey)

        if (savedVersion !== currentVersion) {
            clearLocalStorage()
            clearCookies()
            localStorage.setItem(versionKey, currentVersion)
            // Force the refresh to reinit
            window.location.reload()
        }
    }

    function clearLocalStorage () {
        localStorage.clear()
    }

    function clearCookies () {
        document.cookie.split(';').forEach((cookie) => {
            const cookieName = cookie.split('=')[0].trim()
            deleteCookie(cookieName)
        })
    }

    function getVersionedUrl (url) {
        if (!url) return ''

        const separator = url.includes('?') ? '&' : '?'
        return `${url}${separator}cacheVersion=${version.value}`
    }

    return {
        getVersionedUrl,
        init
    }
}
