<template>
    <div class="Contact">
        <div class="Container">
            <h1 class="h1">
                {{ $t('contact:title') }}
            </h1>
            <debug-application-session v-if="debugMode && debugInformationsDisplay > 2" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import DebugApplicationSession from '@/StoreWeb/components/common/debug/DebugApplicationSession'

const store = useStore()

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const debugMode = computed(() => store.state.debuggingModule.debugMode)
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Contact {
    background: $color-lightest;

    &-internalLinksList {
        li {
            margin-bottom: 15px;
        }
    }

    &-iframe {
        border: none;
    }
}
</style>
