<template>
    <div class="ma-0">
        <HomePageComponent
            class="HomePage-component"
            :isAuthenticated
            @cards-button-clicked="manageHomeEvents"
        />
        <support-reader-modal
            v-model="showSupportReaderModal"
            @operation-finished="onOperationFinished"
        />
        <modal-connection
            v-model="showConnectionModal"
            :isMobile="$vuetify.display.smAndDown"
            :type="homeEventsEnum.CATALOG_CONNECTED"
            @connection="goToConnection"
        />
        <logout-confirmation-modal v-model="showControlModal" />
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal.vue'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { isEmpty } from 'global-utils'
import addCartFlowMixins from '@/StoreWeb/js/mixins/add-cart-flow-utils'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import config from 'config'
import { useI18n } from 'vue-i18n'
import LogoutConfirmationModal from '@/StoreWeb/views/LogoutConfirmationModal.vue'

const store = useStore()
const router = useRouter()
const { isAuthenticated, kasConfigurationSettings } = useLogin()
const { locale, t } = useI18n()

const { triggerLogin } = addCartFlowMixins()

const showSupportReaderModal = ref(false)
const showConnectionModal = ref(false)
const showControlModal = ref(false)

const homeEventsEnum = {
    CATALOG_CONNECTED: 'catalog_connected',
    CARD_READER: 'card_reader',
    CREATE_ACCOUNT: 'create_account',
    GO_TO_FAQ: 'go_to_faq',
    GO_TO_SEARCH: 'go_to_search'
}

const supportContent = computed(() => store.state.anonymousModule.supportContent)

function manageHomeEvents (event) {
    switch (event) {
        case homeEventsEnum.CARD_READER:
            if (isAuthenticated.value) {
                showControlModal.value = true
            } else {
                openCardReaderModal()
            }
            break
        case homeEventsEnum.CATALOG_CONNECTED:
            if (isAuthenticated.value) {
                router.push({ name: 'catalog' })
            } else {
                showConnectionModal.value = true
            }
            break
        case homeEventsEnum.CREATE_ACCOUNT:
            goToConnection()
            break
        case homeEventsEnum.GO_TO_FAQ:
            window.location.href = `${config.cms_url}${t('homepage:card_component:link:faq')}`
            break
        case homeEventsEnum.GO_TO_SEARCH:
            window.location.href = `${config.cms_url}${t('homepage:card_component:link:search')}`
            break
        default:
            console.warn('Case not managed')
            break
    }
}

function goToConnection () {
    if (!isEmpty(kasConfigurationSettings.value?.pages?.registration)) {
        window.location.href = kasConfigurationSettings.value.pages.registration.setUrlParameter('kc_locale', locale.value)
        return
    }

    // Fallback
    triggerLogin({
        callback: showConnectionModal.value = false,
        route: router.resolve({ name: 'catalog' })
    })
}

function closeControlModal () {
    showControlModal.value = false
}

function closeConnectionModal () {
    showConnectionModal.value = false
}

async function onOperationFinished (data) {
    if (!isEmpty(data.support)) {
        const params = {
            providerId: 'conduent',
            fareMediaId: data.support.fareMediaId,
            isTemporary: false
        }

        setSelectedFareMediaCookie(params, true)
        if (isEmpty(supportContent.value)) {
            const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)

            if (content?.id) {
                store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
            }
        }

        router.push({ name: 'walletLight', params })
    }
}

function openCardReaderModal () {
    closeControlModal()
    closeConnectionModal()

    nextTick(() => {
        showSupportReaderModal.value = true
    })
}

onMounted(() => {
    // Hack to manage the window.location.reload
    // Tested from the homepage and other pages and it works as expected
    setTimeout(() => {
        if (localStorage.getItem('openCardReaderAfterLogout')) {
            openCardReaderModal()
        }

        localStorage.removeItem('openCardReaderAfterLogout')
    }, 2000)
})
</script>
