<template>
    <div
        v-if="basket.products.length > 0"
        class="HeaderCartBasket"
    >
        <p
            v-if="basket.info === 'PROVIDERACCOUNTANDDEVICE'"
            class="HeaderCartBasket-number text-left"
        >
            <v-icon icon="mdi mdi-cellphone" />
            <span v-if="basket.deviceLabel"> {{ basket.deviceLabel }} </span>
        </p>
        <p
            v-if="getLabel !== '' || getSubLabel !== ''"
            class="HeaderCartBasket-header"
        >
            <icomoon-icon
                v-if="isCardIconDisplayed"
                name="Card"
                class="HeaderCartBasket-headerIcon"
            />
            <span
                v-if="getLabel || getSubLabel"
                class="HeaderCartBasket-labelWrapper"
            >
                <span
                    v-if="getLabel"
                    class="HeaderCartBasket-label"
                >
                    {{ getLabel }}
                </span>
                <span
                    v-if="getSubLabel"
                    class="HeaderCartBasket-subLabel"
                >
                    {{ getSubLabel }}
                </span>
            </span>
        </p>
        <ul class="HeaderCartBasket-list">
            <header-cart-item
                v-for="cartItem in getBasketProducts"
                v-bind="cartItem"
                :key="cartItem.id"
                class="HeaderCartBasket-item"
            />
        </ul>
    </div>
</template>

<script setup>
import HeaderCartItem from './HeaderCartItem'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import { useCartBasket } from '@/StoreWeb/js/composables/useCartBasket'

const props = defineProps({
    basket: {
        type: Object,
        required: true
    }
})

const {
    getBasketProducts,
    isCardIconDisplayed,
    getLabel,
    getSubLabel
} = useCartBasket(props)
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderCartBasket {
    padding-top: $s4;
    border-top: 1px solid $color-lightgray2;
    white-space: normal;

    &:first-child {
        padding-top: 0;
        border-top: 0;
    }

    &-user {
        @extend %h3;

        margin: 0 0 20px;
        padding: 8px 12px;
        border-radius: 10px;
        background: $color-lightest;
        font-weight: bold;
        text-align: left;
        color: $color-lighterText;
    }

    &-header {
        @extend %h4;

        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
        align-items: center;
        gap: $s1;
        margin: 0;
        padding: $s3;
        border-radius: $border_radius_medium;
        background: $color-brandPrimaryLightBackground;

        :deep(& + .HeaderCartBasket-list) {
            padding-left: $s7;
        }

        :deep(& + .HeaderCartBasket-list .CartItem:first-child) {
            padding-top: 0;
            border-top: 0;
        }
    }

    &-headerIcon {
        font-size: 28px;
        color: $color-brandPrimary;
    }

    &-labelWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        line-height: 1.3;
    }

    &-label {
        font-size: $font-small;
        font-weight: bold;
        color: $color-brandPrimary;
    }

    &-subLabel {
        font-size: $font-xsmall;
        color: $color-lighterText;
    }

    &-list {
        margin: 0;
        padding: $s3 0 $s3 $s4;
        list-style: none;

        &:first-child {
            padding-top: 0;
        }
    }

    :deep(.HeaderCartItem:first-child) {
        border-top: none;
    }
}
</style>
