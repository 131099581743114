<template>
    <div class="FineSelection">
        <div class="Container">
            <h1 class="h1 FineSelection-title">
                {{ $t('fine:regularization:title') }}
            </h1>
            <fine-authentication-choice
                v-if="displayModeSelector"
                @selected-mode="updateSelectedMode"
            />
            <fine-email-form
                v-else-if="displayEmailForm"
                :default-email="email"
                @fine-email="updateEmail"
                @click-on-back-button-from-fine-email-form="clickOnBackButtonFromFineEmailForm"
            />
            <fine-regularization
                v-else
                :email="email"
                :is-anonymous-mode="isAnonymousMode"
                @click-on-back-button-from-fine-regularization="clickOnBackButtonFromFineRegularization"
            />
        </div>
    </div>
</template>

<script>
import FineAuthenticationChoice from '@/StoreWeb/components/fine/FineAuthenticationChoice'
import FineRegularization from '@/StoreWeb/components/fine/FineRegularization'
import { logIn } from '@/StoreWeb/js/composables/login-utils'
import FineEmailForm from '@/StoreWeb/components/fine/FineEmailForm'
import formMixins from '@/StoreWeb/js/mixins/form-mixins'
import config from 'config'

export default {
    components: {
        FineEmailForm,
        FineAuthenticationChoice,
        FineRegularization
    },
    mixins: [
        formMixins
    ],
    data () {
        return {
            isAnonymousMode: false,
            anonymousMode: 'anonymous',
            loginMode: 'loginMode',
            email: '',
            displayModeScreen: false,
            displayEmailScreen: false
        }
    },
    computed: {
        displayModeSelector () {
            return (!this.isAuthenticated && !this.isAnonymousMode) || this.displayModeScreen
        },
        displayEmailForm () {
            return this.displayEmailScreen
        },
        isAuthenticated () {
            if (config.web_harmony.header) {
                return this.$store.state.userModule.isAuthenticated
            }

            return this.$store.state.login.authenticated
        }
    },
    methods: {
        updateSelectedMode (selectedMode) {
            if (selectedMode === this.loginMode) {
                this.updateField('isAnonymousMode', false)
                this.updateField('displayModeScreen', false)
                this.updateField('displayEmailScreen', false)
                logIn(this.$route)
            } else {
                this.updateField('isAnonymousMode', true)
                this.updateField('displayModeScreen', false)
                this.updateField('displayEmailScreen', true)
            }
        },
        updateEmail (email) {
            this.updateField('email', email)
            this.updateField('displayEmailScreen', false)
        },
        clickOnBackButtonFromFineEmailForm () {
            this.updateField('displayModeScreen', true)
            this.updateField('displayEmailScreen', false)
        },
        clickOnBackButtonFromFineRegularization () {
            this.updateField('displayEmailScreen', true)
            this.updateField('displayModeScreen', false)
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FineSelection {
    background: $color-lightest;

    &-instructions {
        font-family: $fontFamily-title;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.88;
    }

    &-help {
        &Link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &Icon {
            font-size: 24px;
        }
    }

    &-description {
        font-family: $fontFamily-title;
        font-weight: bold;
        line-height: 1.88;
    }

    &-field {
        margin-bottom: 20px;
    }
}
</style>
