<template>
    <div
        :class="`HeaderCartContent${classSuffixe}`"
        class="HeaderCartContent"
    >
        <div v-if="!isEmptyCart">
            <header-cart-basket
                v-for="basket in baskets"
                v-bind="basket"
                :key="basket.fareMediaId"
                class="HeaderCartContent-fareMedia"
            />
            <ul
                v-if="packages.length > 0 || plans.length > 0"
                class="HeaderCartContent-list"
            >
                <header-cart-item
                    v-for="cartItem in packages"
                    v-bind="cartItem"
                    :key="cartItem.id"
                    class="HeaderCartContent-item"
                />
                <header-cart-item
                    v-for="cartItem in plans"
                    v-bind="cartItem"
                    :key="cartItem.id"
                    class="HeaderCartContent-item"
                />
            </ul>
            <template v-if="shouldShowTotalAmounts">
                <div class="HeaderCartContent-total">
                    <span class="HeaderCartContent-totalLabel">{{ $t('cart:total_to_settle') }}</span>
                    <formatted-fare
                        :currency="'EUR'"
                        :fare="cartTotal"
                        :size="'Small'"
                        class="HeaderCartContent-totalFare"
                    />
                </div>
                <div
                    v-if="hasDifferentImmediateAndTotalAmount"
                    class="HeaderCartContent-total"
                >
                    <span class="HeaderCartContent-totalLabel">{{ $t('cart:total_to_settle_immediately') }}</span>
                    <formatted-fare
                        :currency="'EUR'"
                        :fare="cartImmediateTotal"
                        :size="'Small'"
                        class="HeaderCartContent-totalFare"
                    />
                </div>
            </template>
            <v-btn
                class="bg-primary w-100"
                size="large"
                height="auto"
                style="height: 52px !important"
                @click.prevent="goToCart"
            >
                {{ $t('cart:go_to_cart') }}
            </v-btn>
            <!-- TODO : Check with the product team whether cart deletion should be permanently removed and in which case delete this commented piece of code
         -->
            <!--                <p class="HeaderCartContent-clearCartButton"> -->
            <!--                    <a -->
            <!--                        class="HeaderCartContent-clearCartButtonLink" -->
            <!--                        @click="clearCart" -->
            <!--                    > -->
            <!--                        {{ $t('button:clear_cart') }} -->
            <!--                    </a> -->
            <!--                </p> -->
        </div>
        <p
            v-else
            class="HeaderCartContent-emptyCart"
        >
            {{ $t('cart:empty') }}
        </p>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useCart } from '@/StoreWeb/js/composables/use-cart'
import { useRouter, useRoute } from 'vue-router'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import HeaderCartBasket from './HeaderCartBasket'
import HeaderCartItem from './HeaderCartItem'
import config from 'config'
import emitter from 'global-emitter'

const router = useRouter()
const route = useRoute()
const store = useStore()

const isMobileApp = computed(() => store.state.isMobileApp)

const { baskets, packages, plans, cartTotal, cartImmediateTotal, hasDifferentImmediateAndTotalAmount, isEmptyCart } = useCart()

const shouldShowTotalAmounts = computed(() => config.features?.cart?.mini_cart_totals || false)

const classSuffixe = computed(() => {
    if (isMobileApp.value) {
        return '--mobileApp'
    }
    if (config.header.template === 'sytral') {
        return '--sytral'
    }
    return '--default'
})

function goToCart () {
    if (route.name !== 'cart') {
        router.push({ name: 'cart' })
    }
    store.commit(cartMutationTypes.SET_SHOW_CART, false)
    emitter.emit('closeCart')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.HeaderCartContent {
    width: 325px;
    max-width: 80vw;
    padding: $s4 $s5 $s6;
    border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, .2);
    background: $color-lightgray1;

    &::before {
        content: '';
        position: absolute;
        top: -9px;
        right: 50px;
        width: 0;
        height: 0;
        border-width: 0 9px 9px;
        border-style: solid;
        border-color: transparent transparent $color-lightgray2 transparent;

        @media (min-width: $tablet-breakpoint) {
            left: 10px;
        }
    }

    &--mobileApp {
        &::before {
            right: 10px;
            left: auto;
        }
    }

    &--sytral {
        &::before {
            left: 60px;

            @media (min-width: $tablet-breakpoint) {
                left: 10px;
            }

            @media (min-width: $desktop-breakpoint) {
                right: 18px;
                left: auto;
            }
        }
    }

    &-list {
        margin: 0;
        padding: 15px 0 0;
        border-top: 1px solid $color-lightgray2;
        list-style: none;

        &:first-child {
            border-top: none;
        }
    }

    &-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 30px;
        padding-top: 10px;
        border-top: 1px solid $color-lightgray2;
    }

    &-totalLabel {
        font-size: 16px;
        font-weight: $fontWeight-defaultMedium;
    }

    &-totalFare {
        flex-grow: 1;
        text-align: right;
        color: $color-defaultText;
    }

    &-clearCart {
        font-size: 14px;
        text-align: center;
    }

    &-clearCartButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 0;
        font-size: 14px;
        font-weight: 500;

        &Link {
            padding: 5px 0;
            cursor: pointer;
            color: $color-lighterText;
            transition: all .3s;

            &:hover,
            &:focus {
                color: $color-brandPrimary;
            }
        }
    }

    &-button {
        width: 100%;
    }

    &-emptyCart {
        margin: 0;
        padding-top: 13px;
        font-size: 14px;
        text-align: center;
    }
}
</style>
